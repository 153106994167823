html, body, #root {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0;
}


body {
  background-color: #bce0f5;
  background-image: url("https://i.imgur.com/CJmPnX6.png");
  background-repeat: repeat;
  background-size: contain;
  animation-name: scroll;
  background-position: 0 0;
  animation: scroll 120s linear infinite;
}

.App {
  height: 100%;
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-rows: 1fr auto;

}

.main {
  align-content: space-between;
  grid-row: 1;
}

header {
  margin: 5px 0;
}

header a:hover {
  color: black;
}

.article {
  max-width: 800px;
  padding: 3% 0;
}

.short {
  max-width: 800px;
}

Footer {
  width: 100%;
  background-image: url("assets/funFooter.png");
  background-repeat: repeat-x;
  justify-content: space-around;
  margin-top: auto;
  padding: 27px 0px 1px;
  grid-row: 2;
  align-self: end;
}

.foots {
  margin: 5px 0 0;
}

p {
  margin: 10px;
}

@keyframes scroll {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}

h1 {
  padding: 0;
  margin: 4px;
}

h2 {
  padding: 2px;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

.flex-container-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-container-column {
  display: flex;
  flex-direction: column;
  align-content: space-around;
}
.centered {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.full-size {
  width: 100%;
  height: 100%;
}

#project-container {
  display: grid;
  grid-template-rows: 1fr 1fr;
}
.link-pic {
  width: 335px;
  height: 200px;
}

.icons {
  max-width: 25px;
}
.badges {
  max-width: 50px;
  max-height: 50px;
}

Nav {
  padding: 3px 0px 1px;
  width: 100%;
  border-top: 1px solid rgb(55, 55, 55);
  border-bottom: 1px solid rgb(55, 55, 55);
  justify-content: space-evenly;
}

.nav-link {
  height: 25px;
  padding: 4px 15px 1px;
  width: 70px;
}

.active {
  background-image: url("assets/SkinnyCloud.png");
  background-size: contain;
  background-repeat: no-repeat;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: rgb(24, 2, 85);
}
a:hover {
  color: rgb(247, 104, 89);
}

.proj-link {
  padding: 10px 5px 5px;
  width: 330px;
}

.me-pic {
  width: 200px;
  float: left;
  margin: 10px;
  padding: 5px 5px 0 0;
}

.attention {
  color: #db123a;
  font-size: large;
}

.space {
  justify-content: space-around;
  width: 100%;
}

.content {
  padding: 5px;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
}


.full-width {
  width: 100%;
}

.music-row {
  display: flex;
  justify-content: space-between;
  width: 500px;
}

.underline {
  text-decoration: underline;
}

.description {
  background-color: rgba(225, 225, 225, 0.8);
  position: absolute;
  z-index: -1;
  width: 325px;
  height: 190px;
  padding: 5px;
  display: none;
  text-align: center;
}

.description p {
  width: 100%;
}

.show {
  display: flex;
  z-index: 10;
  color: black;
  align-items: center;
}

.filler {
  visibility: hidden;
}

#finder-header {
  font-size: 19px;
  font-weight: 700;
}

.scale-results {
  margin: 0 auto;
  justify-content: center;
  flex-wrap: wrap;
}

.scales {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  grid-gap: 5px;
  width: 150px;
  border: 1px solid rgb(66,53,53);
  padding: 2px;
  margin: 5px;
  background-color: rgba(225, 225, 225, 0.8);
  border-radius: 5%;
}

.scale-header {
  grid-column: span 2;
  text-align: center;
}

.scales div {
  max-height: 40px;
}

#note-input {
  width: 60px;
  margin: 1%;
}

#checkbox {
  padding: 0;
  margin-bottom: 5px;
}

.scales span {
  text-decoration: underline;
}

input {
  margin: .5%;
  padding: 2px;
}

@media screen and (max-width: 801px) {
  .project-space {
    justify-content: flex-start;
  }
  .link-pic {
    height: 150px;
    width: 251px;
  }
  .proj-link {
    width: 250px;
  }
  .description {
    width: 245px;
    height: 140px;
  }
}

@media screen and (max-width: 500px) {
  .projects-container {
    height: 100%;
  }
  .article {
    height: 100%;
  }
  .music-row {
    width: 300px;
  }
  .scale-results {
    flex-direction: column;
    align-content: center;
  }
}
